<template>
  <vx-card>
    <form>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('fields.title')}}</strong></legend>
          <div class="vx-col md:w-1/3 w-full my-2">
            <vs-input v-validate="'required'" class="w-full" name="title_tm" placeholder="Turkmen" v-model="campaign.title_tm" />
            <span class="text-danger text-sm" v-show="errors.has('title_tm')">{{ errors.first('title_tm') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-2">
            <vs-input v-validate="'required'" class="w-full" name="title_ru" placeholder="Russian" v-model="campaign.title_ru" />
            <span class="text-danger text-sm" v-show="errors.has('title_ru')">{{ errors.first('title_ru') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-2">
            <vs-input v-validate="'required'" class="w-full" name="title_en" placeholder="English" v-model="campaign.title_en" />
            <span class="text-danger text-sm" v-show="errors.has('title_en')">{{ errors.first('title_en') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pt-2 pb-4" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('fields.description')}}</strong></legend>
          <div class="vx-col md:w-1/3 w-full my-1">
            <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_tm" placeholder="Turkmen" v-model="campaign.description_tm" />
            <span class="text-danger text-sm" v-show="errors.has('description_tm')">{{ errors.first('description_tm') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-1">
            <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_ru" placeholder="Russian" v-model="campaign.description_ru" />
            <span class="text-danger text-sm" v-show="errors.has('description_ru')">{{ errors.first('description_ru') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full my-1">
            <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_en" placeholder="English" v-model="campaign.description_en" />
            <span class="text-danger text-sm" v-show="errors.has('description_en')">{{ errors.first('description_en') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-3 pb-2" style="border-color: rgba(255,255,255,0.6)">
          <legend class="px-2"><strong>{{$t('fields.attachTo')}}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio name="child_type" ref="child_type" v-model="child_type" vs-value="brand">{{$t('brand')}}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio name="child_type" ref="child_type" v-model="child_type" vs-value="category">{{$t('category')}}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('child_type')">{{ errors.first('child_type') }}</span>
          <div class="vx-row">
            <div v-if="child_type === 'brand'" class="vx-col w-full mb-4 mx-2">
              <v-select :options="brands" name="brand_id" :placeholder="$t('select') + ' ' + $t('brand')" v-model="campaign.brandId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
            </div>
            <div v-if="child_type === 'category'" class="vx-col w-full mb-4 mx-2">
              <v-select :options="categories" name="category_id" :placeholder="$t('select') + ' ' + $t('category')" v-model="campaign.categoryId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
            </div>
          </div>
          <div :key="child_type" class="vx-row">
            <div class="vx-col w-full mb-4 mx-2">
              <v-select multiple :options="products" name="products" :placeholder="$t('selectProducts')" v-model="selected.products" @search="getOpt" :label="`name_${$i18n.locale}`" />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-4 mx-2">
              <v-select multiple :options="collections" name="combos" :placeholder="$t('selectCombos')" v-model="selected.collections" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-3 pb-2" style="border-color: rgba(255,255,255,0.6)">
          <legend class="px-2"><strong>{{$t('fields.type')}} & {{$t('fields.state')}}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="vx-col my-5  mr-4 flex">
              <vs-switch name="isActive" ref="isActive" v-model="campaign.isActive" />
              <label class="ml-3">{{$t('isActive')}}?</label>
            </li>
          </ul>
        </fieldset>
      </div>
      <div class="vx-row">
        <div class="vx-col upload-img mt-3" v-if="!campaign.photo">
          <input v-validate="'required_if:isActive,true'" name="photo" type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()" type="border" icon-pack="feather" icon="icon-upload">{{ $t('upload') }} {{ $t('image') }}</vs-button>
          <span class="text-danger text-sm" v-show="errors.has('photo')">{{ errors.first('photo') }}</span>
        </div>
      </div>
      <template v-if="campaign.photo">
        <!-- Image Container -->
        <div class="img-container w-64 mx-auto flex items-center justify-center">
          <img :src="campaign.photo" alt="img" class="responsive">
        </div>
        <!-- Image upload Buttons -->
        <div class="modify-img flex justify-between mt-5">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{ $t('update') }} {{ $t('image') }}</vs-button>
          <vs-button type="flat" color="#999" @click="campaign.photo = null">{{ $t('remove') }} {{ $t('image') }}</vs-button>
        </div>
      </template>
      <div class="px-4 vx-row">
        <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">{{$t('submit')}}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="$router.back()" class="ml-4 mt-5 block">{{$t('cancel')}}</vs-button>
      </div>
      <!-- <vs-upload text="Slider image" action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" /> -->
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      campaign: {
        title_tm: '',
        title_ru: '',
        title_en: '',
        description_tm: '',
        description_ru: '',
        description_en: '',
        categoryId: null,
        brandId: null,
        photo: null,
        type: '',
        isActive: false
      },
      selected: { products: [], collections: [] },
      child_type: null,
      brands: [],
      categories: [],
      products: [],
      collections: [],
      types: [
        {name_en: 'both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {name_en: 'market', name_tm: 'Market', name_ru: 'Маркет'},
        {name_en: 'express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      filter: { isActive: true, canPublished: true }
    }
  },
  watch: {
    child_type (newVal, oldVal) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      if (newVal !== oldVal && oldVal) {
        if (newVal === 'brand') {
          this.campaign.categoryId = null
        } else if (newVal === 'category') {
          this.campaign.brandId = null
        }
      }
    },
    async 'campaign.brandId' (newValue, oldValue) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      if (newValue !== oldValue && newValue) {
        await this.$http.get('/products', { params: { ...this.filter, brandIds: [newValue] } }).then(response => {
          this.campaign.items = []
          this.products = response.data.data
        })
      }
    },
    async 'campaign.categoryId' (newValue, oldValue) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      if (newValue !== oldValue && newValue) {
        await this.$http.get('/products', { params: { ...this.filter, categoryIds: [newValue] } }).then(response => {
          this.campaign.items = []
          this.products = response.data.data
        })
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async getOpt (e) {
      if (e.length > 2) {
        await this.$http.get('/products', { params: { ...this.filter, keyword: e } }).then(response => {
          this.products = response.data.data
        })
      }
    },
    async fetchData () {
      await this.$http.get('/brands', { params: { isActive: true } }).then(response => {
        this.brands = response.data.data
      })
      await this.$http.get('/categories', { params: { isActive: true } }).then(response => {
        this.categories = response.data.data
      })
      await this.$http.get('/collections', { params: { isActive: true } }).then(response => {
        this.collections = response.data.data
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let items = this.selected.products.map(el => { return { type: 'product', id: el.id } })
          items = items.concat(this.selected.collections.map(el => { return { type: 'collection', id: el } }))
          this.$http.post('/campaigns/add', {
            ...this.campaign,
            items
          }).then(response => {
            if (response.status === 201) {
              this.$vs.notify({
                title: 'Success',
                text: 'Banner successfully created!',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'primary'
              })
              this.$router.push(`/campaigns/${response.data.uuid}`)
            }
          }).catch(err => {
            this.$vs.notify({
              title: 'Error',
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          this.$vs.notify({
            title: 'Error',
            text: 'Form invalid. Please check and try again!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.campaign.photo = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.theme-dark {
  fieldset {
    border-color: #435473 !important;
  }
}
</style>
